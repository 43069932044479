<style scoped>
    .bg-blue-hover.active,.bg-blue-hover:hover{
        background: linear-gradient(90deg, transparent, var(--bs-light) 50%, transparent);
        color: #005ea6;
    }
    .form-control-nofocus:focus{
        box-shadow:none;
    }
    @media (min-width: 576px){
        .list-item:last-child{
            border-bottom: 1px solid #dee2e6 !important;
        }
        .list-item:nth-of-type(odd) > * {
            background: rgba(0, 0, 0, 0.05);
        }
        .list-item:hover {
            background: rgba(0, 0, 0, 0.075);
        }
    }
</style>
<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <div class="row flex-grow-1 h-0 mb-1 mb-sm-0">
            <!-- 菜单 -->
            <div class="col-12 col-sm-2 accordion h-100" :class="[currTab==''?'':'d-none d-sm-block']" id="accordionParent">
                <div class="h-100 bg-blue text-light bg-xs-transparent rounded mx-2 mx-sm-0">
                    <div class="accordion-item bg-blue mb-2 mb-sm-0 rounded-xs">
                        <div class="bg-navy accordion-header fw-bold py-2 px-3 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseOne" role="button">{{$t('personal.funds')}}</div>
                        <div id="collapseOne" class="accordion-collapse collapse d-xs-block" :class="{show:['recharge','withdraw'].indexOf(currTab)!=-1}" data-bs-parent="#accordionParent">
                            <div class="accordion-body d-flex flex-wrap p-sm-0">
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='recharge'}" @click="changeTab('recharge',$t('personal.recharge'))" role="button">
                                    <i class="bi bi-wallet2 fs-2 text-success d-sm-none"></i>
                                    <span>{{$t('personal.recharge')}}</span>
                                </div>
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='withdraw'}" @click="changeTab('withdraw',$t('personal.withdraw'))" role="button">
                                    <i class="bi bi-cash-coin fs-2 text-secondary d-sm-none"></i>
                                    <span>{{$t('personal.withdraw')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item bg-blue mb-2 mb-sm-0 rounded-xs">
                        <div class="bg-navy accordion-header fw-bold py-2 px-3 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseTwo" role="button">{{$t("personal.record")}}</div>
                        <div id="collapseTwo" class="accordion-collapse collapse d-xs-block" :class="{show:['rechargeLog','withdrawLog','submitLog'].indexOf(currTab)!=-1}" data-bs-parent="#accordionParent">
                            <div class="accordion-body d-flex flex-wrap p-sm-0">
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='rechargeLog'}" @click="changeTab('rechargeLog',$t('personal.rechargeLog'))" role="button">
                                    <i class="bi bi-terminal-plus fs-2 text-primary d-sm-none"></i>
                                    <span>{{$t("personal.rechargeLog")}}</span>
                                </div>
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='withdrawLog'}" @click="changeTab('withdrawLog',$t('personal.withdrawLog'))" role="button">
                                    <i class="bi bi-terminal-dash fs-2 text-primary d-sm-none"></i>
                                    <span>{{$t("personal.withdrawLog")}}</span>
                                </div>
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='submitLog'}" @click="changeTab('submitLog',$t('personal.submitLog'))" role="button">
                                    <i class="bi bi-terminal fs-2 text-primary d-sm-none"></i>
                                    <span>{{$t("personal.submitLog")}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item bg-blue mb-2 mb-sm-0 rounded-xs">
                        <div class="bg-navy accordion-header fw-bold py-2 px-3 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseThree" role="button">{{$t('personal.updateInformation')}}</div>
                        <div id="collapseThree" class="accordion-collapse collapse d-xs-block" :class="{show:['updateSource','updatePwd'].indexOf(currTab)!=-1}" data-bs-parent="#accordionParent">
                            <div class="accordion-body d-flex flex-wrap p-sm-0">
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='updateSource'}" @click="changeTab('updateSource',$t('personal.updateSource'))" role="button">
                                    <i class="bi bi-collection-fill fs-2 text-warning d-sm-none"></i>
                                    <span>{{$t('personal.updateSource')}}</span>
                                </div>
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='updatePwd'}" @click="changeTab('updatePwd',$t('personal.updatePwd'))" role="button">
                                    <i class="bi bi-pencil-square fs-2 text-secondary d-sm-none"></i>
                                    <span>{{$t('personal.updatePwd')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item bg-blue mb-2 mb-sm-0 rounded-xs">
                        <div class="bg-navy accordion-header fw-bold py-2 px-3 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseFour" role="button">{{$t('personal.promote')}}</div>
                        <div id="collapseFour" class="accordion-collapse collapse d-xs-block" :class="{show:['share','problem','agreement','about'].indexOf(currTab)!=-1}" data-bs-parent="#accordionParent">
                            <div class="accordion-body d-flex flex-wrap p-sm-0">
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='share'}" @click="changeTab('share',$t('personal.share'))" role="button">
                                    <i class="bi bi-box-arrow-up-right fs-2 text-success d-sm-none"></i>
                                    <span>{{$t('personal.share')}}</span>
                                </div>
                                <div class="d-flex flex-column text-center col-3 py-2 col-sm-12 bg-blue-hover rounded" :class="{active:currTab=='problem'}" @click="changeTab('problem',$t('personal.problem'))" role="button">
                                    <i class="bi bi-patch-question fs-2 d-sm-none"></i>
                                    <span>{{$t('personal.problem')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 内容 -->
            <div class="col-sm-10 rounded h-100 ps-sm-0 d-sm-block" :class="[currTab==''?'d-none':'']">
                <div class="bg-blue text-light card h-100">
                    <div class="card-header fw-bold bg-navy d-flex justify-content-between">
                        <span>{{currTabName}}</span>
                        <i class="bi bi-x-square" role="button" @click="$root.goPage('home')"></i>
                    </div>
                    <div class="mx-3 py-2 border-bottom d-sm-none">
                        <span class="fw-bold" @click="currTab=''" role="button">
                            <i class="bi bi-box-arrow-left pe-1"></i>
                            <span>{{$t("personal.back")}}</span>
                        </span>
                    </div>
                    <div class="card-body d-flex flex-column justify-content-center">
                        <modules :curr-modal="currModules" class="d-flex justify-content-center flex-grow-1"></modules>
                    </div>
                </div>
            </div>
        </div>
        <foot-nav></foot-nav>
    </div>
</template>
<script>
//使用二维码插件，安装：npm install vue-qr --save，官网：https://github.com/Binaryify/vue-qr#readme
import navbar from '@/components/public/navbar.vue';
import footNav from '@/components/public/footNav.vue'
import modules from "@/components/public/modules.vue";
export default {
    components: { footNav,navbar,modules },
    props: {
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            currTab: getComputedStyle(document.getElementById("wrapper")).width.replace("px","")>=576?'recharge':'',//当前选中的选项卡
            currTabName:'',//当前选中的选项卡名
            resizeTimer:null,//改变视口大小定时器
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        currModules(){
            let moduleName="";
            switch (this.currTab) {
                case "recharge":
                    moduleName=this.$t('personal.recharge');
                    break;
                case "withdraw":
                    moduleName=this.$t('personal.withdraw');
                    break;
                case "updatePwd":
                    moduleName=this.$t('common.safeSetting');
                    break;
                case "updateSource":
                    moduleName=this.$t('personal.updateSource');
                    break;
                case "share":
                    moduleName=this.$t('common.vipShare');
                    break;
                case "problem":
                    moduleName=this.$t('common.questionFeedBack');
                    break;
                case "rechargeLog":
                case "withdrawLog":
                case "submitLog":
                    moduleName=this.currTabName;
                    break;
            }
            return moduleName;
        }
    },
    mounted() {
        //设置窗口大小改变
        addEventListener("resize",this.setResize);
    },
    unmounted() {
        removeEventListener("resize",this.setResize);
    },
    methods: {
        /**
         * 改变选项
         */
        changeTab(tabId,tabName) {
            this.currTab=tabId;
            this.currTabName=tabName;
        },
        /**
         * 设置窗口大小改变
         */
        setResize(){
            this.resizeTimer&&clearTimeout(this.resizeTimer);
            this.resizeTimer=setTimeout(()=>{
                if(getComputedStyle(document.getElementById("wrapper")).width.replace("px","")>=576){
                    this.currTab==""&&this.changeTab("recharge",this.$t('personal.recharge'));
                }
            },100)
        },
    }
}
</script>