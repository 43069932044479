<!-- 切换台面 -->
<style scoped>
    .change-table{
        transform: translate(100%,0);
        transition: transform .3s cubic-bezier(.25, 1.15, .35, 1.08);
        z-index: 1051;
    }
    .change-table.active{
        transform: translate(0,0);
    }
</style>
<template>
    <div @click.stop :class="{active:isShowTable}" class="d-flex col-11 col-sm-6 col-md-6 col-lg-5 h-100 position-absolute change-table py-2 end-0 bg-dark rounded-start bg-opacity-75">
        <div class="d-flex flex-column">
            <!-- 伸展书签 -->
            <div @click.stop="showTable" class="d-flex position-absolute top-50 text-vertical start-0 bg-danger text-light rounded-start border border-2 border-warning border-end-0 translate-start-x-middle-y lh-sm px-1 py-2" role="button">
                <span>{{$t("room.changeTable")}}</span>
            </div>
            <!-- 游戏 -->
            <ul class="nav nav-tabs fw-bold px-2">
                <li v-for="item in gameList" :key="item" class="nav-item">
                    <span class="nav-link py-1" :class="item==tabGame?'active':'text-light'" @click="getRoomList(item)">{{$t("common."+GAME_CODE[item])}}</span>
                </li>
            </ul>
            <!-- 台列表 -->
            <div class="flex-grow-1 h-0 text-light mt-2">
                <room-list :class="[$root.hasPearl(tabGame)?'col-12':'col-3']" :room-list="roomList" :count-list="countList" :pearl-obj="pearlObj" @click-room="isShowTable=false" @get-room-pearl="getRoomPearl" @on-ready="isModuleReady=true"></room-list>
            </div>
        </div>
    </div>
</template>
<script>
import { GAME_CODE,MUTATION_TYPE,GAME_ID } from '@/assets/js/config';
import roomList from '@/components/public/roomList.vue';
export default {
    components:{
        roomList
    },
    props: {
        //台面列表
        roomList:{
            type:Array,
            default:[]
        },
        //路单统计列表
        countList:{
            type:Array,
            default:[]
        },
        //要重绘路单的对象
        pearlObj:{
            id:0,//台面id
            pearl:[]//路单数据
        },
    },
    data() {
        return {
            GAME_CODE,
            isShowTable:false,//是否展开切换台面
            isModuleReady:false,//页面内组件是否加载完成
        };
    },
    computed: {
        roomInfo() {
            return this.$store.state.roomInfo;
        },
        activeGame(){
            return this.$store.state.activeGame;
        },
        tabGame(){
            return this.$store.state.tabGame;
        },
        gameList(){
            return this.$store.state.gameList;
        }
    },
    created() {
        this.$store.commit({
            type:MUTATION_TYPE.setTabGame,
            tabGame:this.roomInfo.game==GAME_ID.lh?GAME_ID.bac:this.roomInfo.game
        })
    },
    methods: {
        /**
         * 展开或合上切换台面列表
         */
        showTable(){
            this.isShowTable=this.isShowTable?false:true;
            if(this.isShowTable){//显示状态下点击空白处隐藏
                document.onclick=(e)=>{
                    e.stopPropagation();
                    this.isShowTable=false;
                }
            }
        },
        /**
         * 获取游戏对应台面列表
         */
        getRoomList(gameId){
            if(this.isModuleReady){//当页面内组件加载完成时才能再次点击
                this.isModuleReady=false;
                (!this.$root.changeGame(gameId,"tabGame"))&&(this.isModuleReady=true);//切换不成功需要把组件加载状态置回空闲，否则下次无法操作
            }
        },
        /**
         * 获取台面路单
         * @param {Number} tableId 台面id
         * @param {Number} column 获取的数据列数
         */
        getRoomPearl(gameId,tableId,column){
            this.$emit("get-room-pearl",gameId,tableId,column);
        },
    },
};
</script>