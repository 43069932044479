<!-- 视频上公告栏 -->
<template>
    <div class="d-none d-sm-flex align-items-center flex-grow-1 position-absolute top-0 start-0 end-0 bg-dark bg-opacity-50 text-light index-2">
        <i class="bi bi-megaphone-fill mx-2 py-1 text-warning"></i>
        <div v-if="$route.name!='share'" class="flex-grow-1 notice-short d-flex px-2 overflow-hidden" role="button">
            <div class="w-100 rounded-pill position-relative width-max marquee text-nowrap">
                <label>{{notice}}</label>
            </div>
        </div>
        <div class="notice-long position-absolute bg-dark bg-opacity-50 p-2 rounded w-100 index-1 top-100">{{notice}}</div>
    </div>
</template>
<script>
export default {
    inject: {
        //公告内容
        notice:{
            default:""
        }
    }
}
</script>