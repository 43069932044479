import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {USER} from '@/assets/js/config.js'
//使用bootstrap框架，安装：npm install bootstrap，官网：https://getbootstrap.com/ https://www.bootcss.com/
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
//使用bootstrap图标，安装：npm i bootstrap-icons，官网：https://icons.getbootstrap.com
import 'bootstrap-icons/font/bootstrap-icons.css'
//使用多语言插件，安装：npm install vue-i18n@next，官网：https://kazupon.github.io/vue-i18n/zh/
import i18n from './lang';
//使用弹出插件，安装：npm i layer-src，官网：https://www.layui.site/layer/mobile/api.html
import 'layer-src/dist/mobile/layer';
import "layer-src/dist/mobile/need/layer.css";
//使用音效插件，安装：npm i -S @liripeng/vue-audio-player，官网：https://github.com/1014156094/vue-audio-player
import AudioPlayer from '@liripeng/vue-audio-player'
//使用axios，安装：npm install --save axios vue-axios，官网：http://www.axios-js.com/zh-cn/docs/vue-axios.html
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.headers.post['Content-Type']="multipart/form-data";
// axios.defaults.baseURL="http://192.168.1.20:8085/h_media.ashx";
axios.interceptors.response.use((res)=>{
    if(res.data.d){
        try {
            return JSON.parse(res.data.d)
        } catch (error) {
            return res.data.d;
        }
    }
    return res.data;
})



let app=createApp(App);
app.config.unwrapInjectedRef = true;
//注册全局获取焦点指令
app.directive('focus',{
    mounted(elem) {
        elem.focus();
    },
})

//加载对应的配置文件
let script = document.createElement("script");
script.setAttribute("src","config/"+USER+".js");
document.body.appendChild(script);
script.onload=script.onreadystatechange=function () {
    if(!this.readyState||this.readyState=='loaded'||this.readyState=='complete'){
        if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad)/i)){//苹果端需要先加载nodeplayer
            NodePlayer.load(() => {
                app.use(store).use(router).use(VueAxios, axios).use(i18n).use(AudioPlayer).mount('#app');
            });
        }else{
            app.use(store).use(router).use(VueAxios, axios).use(i18n).use(AudioPlayer).mount('#app');
        }
    }
    script.onload=script.onreadystatechange=null;
}
