import { RATIO } from "@/assets/js/config.js";
/**
 * 生成GUID
 */
function guid() {
    var str = '';
    var i = 32;
    while (i--) {
        str += Math.floor(Math.random() * 16.0).toString(16);
    }
    return str;
}
//获取当前时间
function getDate() {
    let date = new Date();
    let str=
        date.getFullYear() + "-" +
        (date.getMonth() + 1<10?"0"+(date.getMonth()+1):date.getMonth()+1) + "-" +
        (date.getDate()<10?"0"+date.getDate():date.getDate()) + " " +
        (date.getHours()<10?"0"+date.getHours():date.getHours()) + ":" +
        (date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes());// + ":" + date.getSeconds();
    return str;
}
/**
 * 获取操作系统
 */
function getSystem(){
    let userAgent=navigator.userAgent;
    let isXiaomi=userAgent.indexOf('XiaoMi')!=-1;//小米手机
    let isAndroid=userAgent.indexOf('Android')!=-1||userAgent.indexOf('Linux')!=-1;//安卓
    let isIos=!!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);//IOS
    if(isXiaomi||isAndroid){
        return 'android';
    }else if(isIos){
        return 'ios';
    }else{
        return "";
    }
}
/**
 * 获取当前浏览设备类型(PC端：h5_web_4 移动端：h5_phone_4)
 */
function getDeviceType() {
    if(!!navigator.userAgent.match(/AppleWebKit.*Mobile.*/)){//移动端
        return 'h5_phone_4';
    } else {
        return 'h5_web_4';
    }
}
/**
 * 复制到剪切板
 */
async function copy(text){
    if(!navigator.clipboard){//浏览器不支持clipboard
        //使用将废弃的execCommand
        let textarea=document.createElement("textarea");
        textarea.value=window.location.href;
        textarea.style.top="0";
        textarea.style.left="0";
        textarea.style.position="fixed";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            let result = document.execCommand("copy");
            if(result){
                return "success";
            }
        } catch (error) {
            return "error";
        }
        document.body.removeChild(textarea);
    }else{
        navigator.clipboard.writeText(text);
        return "success";
    }
}
/**
 * 生成验证码
 */
function drawCode(canvas){
    // let canvas = document.getElementById("code");
    let show_num=[];
    let canvasParent=canvas.parentElement;
    var context = canvas.getContext("2d");
    var sCode = "A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
    var aCode = sCode.split(",");
    var aLength = aCode.length;//获取到数组的长度
    canvas.width = RATIO*(canvasParent.clientWidth-getComputedStyle(canvasParent,false).paddingLeft.replace('px','')-getComputedStyle(canvasParent,false).paddingRight.replace('px',''));
    canvas.height = RATIO*canvasParent.clientHeight;
    
    for (var i = 0; i <= 3; i++) {
        var j = Math.floor(Math.random() * aLength);//获取到随机的索引值
        var deg = Math.random() * 30 * Math.PI / 180;//产生0~30之间的随机弧度
        var txt = aCode[j];//得到随机的一个内容
        show_num[i] = txt.toLowerCase();
        var x = (i*(canvas.width/4));//文字在canvas上的x坐标
        // var y = Math.random() *(canvas.height/1.2-canvas.height/2+1)+canvas.height/2;//文字在canvas上的y坐标
        var y=canvas.height/1.3;
        context.font = "bold "+canvas.width/4+"px 微软雅黑";

        context.translate(x, y);
        context.rotate(deg);

        context.fillStyle = randomColor();
        context.fillText(txt, 0, 0);

        context.rotate(-deg);
        context.translate(-x, -y);
    }
    for (var i = 0; i <= 5; i++) { //验证码上显示线条
        context.strokeStyle = randomColor();
        context.beginPath();
        context.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
        context.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
        context.stroke();
    }
    for (var i = 0; i <= 30; i++) { //验证码上显示小点
        context.strokeStyle = randomColor();
        context.beginPath();
        var x = Math.random() * canvas.width;
        var y = Math.random() * canvas.height;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
    }
    return show_num.join('');
}
/**
 * 得到随机的rgb颜色值
 */
function randomColor() {
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
    return "rgb(" + r + "," + g + "," + b + ")";
}
/**
 * 格式化日期
 */
function formatDate(date) {
    if (date === null || date === '')
        return '';
    var a = new Date(date);
    var y = a.getFullYear();
    var m = a.getMonth() + 1;
    var d = a.getDate();
    var h = a.getHours();
    var mm = a.getMinutes();
    var s = a.getSeconds();

    if (m < 10) m = '0' + m;
    if (d < 10) d = '0' + d;
    if (h < 10) h = '0' + h;
    if (mm < 10) mm = '0' + mm;
    if (s < 10) s = '0' + s;
    return y + '-' + m + '-' + d + " " + h + ":" + mm + ":" + s;
}
/**
 * 获取IP
 */
function getIP() {
    try {
        return returnCitySN;
    } catch (e) {
        return {
            cip: '127.0.0.1',
            cname: '无法获取'
        }
    }
}
/**
 * 全屏/取消全屏
 */
function fullScreen(){
    return new Promise((resolve)=>{
        let elem=document.documentElement;
        if(document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled){//可以全屏
            if(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement){//已在全屏状态
                //退出全屏
                if (document.exitFullscreen) {
                    document.exitFullscreen().then(()=>resolve(0));
                }else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen().then(()=>resolve(0));
                }else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen().then(()=>resolve(0));
                }else if (document.msExitFullscreen) {
                    document.msExitFullscreen().then(()=>resolve(0));
                }
            }else{//不在全屏状态
                //进入全屏
                if (elem.requestFullscreen) {
                    elem.requestFullscreen().then(()=>resolve(1));
                }else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen().then(()=>resolve(1));
                }else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen().then(()=>resolve(1));
                }else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen().then(()=>resolve(1));
                }
            }
        }else{
            // useLayer({str:this.$t("app.unFullscreen")});
            resolve(-1);
        }
    })
}

//13-27-4-6-47-13-33-37-
var result=[
    7,40,36,9,32,35,2,18,36,11,11,4,47,48,4,21,30,9,31,23,5,39,11,16,30,12,
    2,13,23,5,26,21,46,6,21,3,49,39,4,46,27,32,46,9,42,8,48,20,40,49,6,9,2,18,7,21,37,5,19,35,25,1,39,37,30,31,27,6,30,35,33,44,1,24,4,13,
    28,6,8,23,47,39,24,33,16,28,20,36,16,18,14,1,43,15,49,41,1,28,44,30,42,21,35,22,11,2,31,35,21,43,6,43,9,7,14,6,1,7,13,17,5,29,37,6,14,22,
    1,33,6,30,12,19,3,7,43,44,4,14,42,5,17,37,10,17,6,46,12,4,10,10,12,36,41,6,32,44,2,4,34,23,8,43,36,8,1,31,46,46,3,48,44,8,31,29,47,32,
    18,8,44,3,4,25,3,11,38,49,28,40,7,28,34,42,14,49,36,18,19,10,20,38,31,9,45,29,20,3,29,33,36,45,13,12,13,4,19,15,23,14,17,20,20,18,25,37,13,3,
    21,29,14,34,9,26,49,1,34,36,9,29,17,20,31,39,9,25,2,32,3,13,8,39,27,28,13,10,8,18,39,10,46,16,38,33,6,41,19,39,23,21,42,48,25,20,35,44,20,39,
    32,24,36,25,32,15,28,34,28,16,48,16,13,30,5,10,26,29,48,41,49,42,23,19,32,2,26,7,35,21,1,35,18,46,8,37,6,24,31,16,41,10,46,34,5,1,47,7,9,46,
    37,28,14,44,41,10,13,18,29,
    // 28,14,44,41,10,13,18,29,42,1,8,26,19,36,12,1,43,
    // 6,26
];//22-365
var score={};
for (let i = 1; i <= 49; i++) {
    score[i]=0;
}
function test(){
    var number=[];//号码
    for (let i = 1; i <= 49; i++) {
        number.push(i);
    }
    var animal={
        1:[4,16,28,40],
        2:[3,15,27,39],
        3:[2,14,26,38],
        4:[1,13,25,37,49],
        5:[12,24,36,48],
        6:[11,23,35,47],
        7:[10,22,34,46],
        8:[9,21,33,45],
        9:[8,20,32,44],
        10:[7,19,31,43],
        11:[6,18,30,42],
        12:[5,17,29,41]
    }
    var animaldx={
        d1:[28,40],
        d2:[27,39],
        d3:[26,38],
        d4:[25,37,49],
        d5:[36,48],
        d6:[35,47],
        d7:[34,46],
        d8:[33,45],
        d9:[32,44],
        d10:[31,43],
        d11:[30,42],
        d12:[29,41],
        x1:[4,16],
        x2:[3,15],
        x3:[2,14],
        x4:[1,13],
        x5:[12,24],
        x6:[11,23],
        x7:[10,22],
        x8:[9,21],
        x9:[8,20],
        x10:[7,19],
        x11:[6,18],
        x12:[5,17]
    }
    var color={
        red:[1,2,7,8,12,13,18,19,23,24,29,30,34,35,40,45,46],
        blue:[3,4,9,10,14,15,20,25,26,31,36,37,41,42,47,48],
        green:[5,6,11,16,17,21,22,27,28,32,33,38,39,43,44,49]
    }
    var colorArt={
        rqing:[13,19,23,35],
        bqing:[25,31,37,47],
        gqing:[11,43,49],
        rqi:[18,30,40],
        bqi:[3,4,15,42],
        gqi:[6,16,27,28,39],
        rshu:[2,12,24,34,46],
        bshu:[10,14,26,36,48],
        gshu:[22,38],
        rhua:[29,45],
        bhua:[9,20,41],
        ghua:[5,17,21,32,33,44],
    }
    var colorSeason={
        redc:[1,13,2,12,24],
        bluec:[25,37,14,26,36,48],
        greenc:[49,38],
        redx:[23,35,34,46,45],
        bluex:[47,10,9],
        greenx:[11,22,21,33],
        redq:[8,7,19,18,30],
        blueq:[20,31,42],
        greenq:[32,44,43,6],
        redd:[40,29],
        blued:[4,3,15,41],
        greend:[16,28,27,39,5,17]
    }
    var colorSafe={
        redj:[1,7,8,12,13,19,23,24,34,35,45,46],
        bluej:[9,10,25,31,36,37,47,48],
        greenj:[11,21,22,33,43,49],
        redx:[2,18,29,30,40],
        bluex:[3,4,14,15,20,26,41,42],
        greenx:[5,6,16,17,27,28,32,38,39,44]
    }
    var colorEight={
        redd:[12,18,23,24,29,30,34,35,40,46],
        blued:[3,10,36,41,42,47,48],
        greend:[5,6,11,16,17,22,28],
        redl:[1,2,7,8,13,19,45],
        bluel:[4,9,14,15,20,25,26,31,37],
        greenl:[21,27,32,33,38,39,43,44,49],
    }
    var colorSex={
        rm:[2,8,12,18,23,24,30,34,40,46],
        bm:[3,4,10,14,15,20,26,36,42,48],
        gm:[6,16,22,27,28,32,38,39,44],
        rf:[1,7,13,19,29,35,45],
        bf:[9,25,31,37,41,47],
        gf:[5,11,17,21,33,43,49],
    }
    var colorWorld={
        rt:[12,24,1,13,34,46,8,29],
        rd:[40,2,23,35,45,7,19,18,30],
        bt:[36,48,25,37,3,15,10,20,41],
        bd:[4,14,26,47,9,31,42],
        gt:[49,27,39,22,32,44,5,17],
        gd:[16,28,38,11,21,33,43,6]
    }
    var colorSide={
        rj:[7,18,19,29,30,34,45,46],
        ry:[1,2,8,12,13,23,24,35,40],
        bj:[3,9,10,15,31,41,42],
        by:[4,14,20,25,26,36,37,47,48],
        gj:[5,6,17,21,22,27,33,39,43],
        gy:[11,16,28,32,38,44,49]
    }
    var colorAnimalColor={
        redra:[1,7,13,19,34,40,46],
        redba:[12,18,24,30,45],
        redga:[2,8,23,29,35],
        bluera:[4,10,25,31,37],
        blueba:[3,9,15,36,42,48],
        bluega:[14,20,26,41,47],
        greenra:[16,22,28,43,49],
        greenba:[6,21,27,33,39],
        greenga:[5,11,17,32,38,44]
    }
    var colorFive={
        jr:[1,2,23,24,40],
        jb:[9,10,31],
        jg:[32,39],
        mr:[13,35],
        mb:[14,36],
        mg:[5,6,21,22,43,44],
        sr:[12,19,28],
        sb:[20,41,42],
        sg:[11,27,28,49],
        hr:[7,8,29,30,45,46],
        hb:[15,37],
        hg:[16,38],
        tr:[18,34],
        tb:[3,4,25,26,47,48],
        tg:[17,33],
    }
    var colorDoor={
        r1:[1,2,7,8],
        r2:[12,13,18,19],
        r3:[23,24,29],
        r4:[30,34,35],
        r5:[40,45,46],
        b1:[3,4,9],
        b2:[10,14,15],
        b3:[20,25,26],
        b4:[31,36,37],
        b5:[41,42,47,48],
        g1:[5,6],
        g2:[11,16,17],
        g3:[21,22,27,28],
        g4:[32,33,38,39],
        g5:[43,44,49],
    }
    var colorDs={
        redOdd:[1,7,13,19,23,29,35,45],
        redEven:[2,8,12,18,24,30,34,40,46],
        blueOdd:[3,9,15,25,31,37,41,47],
        blueEven:[4,10,14,20,26,36,42,48],
        greenOdd:[5,11,17,21,27,33,39,43,49],
        greenEven:[6,16,22,28,32,38,44]
    }
    var colorDx={
        redSmall:[1,2,7,8,12,13,18,19,23,24],
        redBig:[29,30,34,35,40,45,46],
        blueSmall:[3,4,9,10,14,15,20],
        blueBig:[25,26,31,36,37,41,42,47,48],
        greenSmall:[5,6,11,16,17,21,22],
        greenBig:[27,28,32,33,38,39,43,44,49],
    }
    var five={
        j:[1,2,9,10,23,24,31,32,39,40],
        m:[5,6,13,14,21,22,35,36,43,44],
        s:[11,12,19,20,27,28,41,42,49],
        h:[7,8,15,16,29,30,37,38,45,46],
        t:[3,4,17,18,25,26,33,34,47,48]
    }
    var fiveDs={
        jd:[1,9,23,31,39],
        js:[2,10,24,32,40],
        md:[5,13,21,35,43],
        ms:[6,14,22,36,44],
        sd:[11,19,27,41,49],
        ss:[12,20,28,42],
        hd:[7,15,29,37,45],
        hs:[8,16,30,38,46],
        td:[3,17,25,33,47],
        ts:[4,18,26,34,48]
    }
    var fiveDx={
        jd:[31,32,39,40],
        jx:[1,2,9,10,23,24],
        md:[35,36,43,44],
        mx:[5,6,13,14,21,22],
        sd:[27,28,41,42,49],
        sx:[11,12,19,20],
        hd:[29,30,37,38,45,46],
        hx:[7,8,15,16],
        td:[25,26,33,34,47,48],
        tx:[3,4,17,18]
    }
    var fiveSafe={
        jj:[1,9,10,23,24,31],
        jx:[2,32,39,40],
        mj:[13,21,22,35,36,43],
        mx:[5,6,14,44],
        sj:[11,12,19,49],
        sx:[20,27,28,41,42],
        hj:[7,30,37,45,46],
        hx:[8,15,16,29,38],
        tj:[25,33,34,47,48],
        tx:[3,4,17,18,26],
    }
    var fiveEight={
        jd:[10,23,24,40],
        jl:[1,2,9,31,32,39],
        md:[5,6,22,35,36],
        ml:[13,14,21,43,44],
        sd:[11,12,28,41,42],
        sl:[19,20,27,49],
        hd:[16,29,30,46],
        hl:[7,8,15,37,38,45],
        td:[4,17,18,34,47,48],
        tl:[3,25,26,33]
    }
    var fiveSex={
        jm:[1,9,23,31],
        jf:[2,10,24,32,39,40],
        mm:[5,13,21,35,43],
        mf:[6,14,22,36,44],
        sm:[11,19,41,49],
        sf:[12,20,27,28,42],
        hm:[7,29,37,45],
        hf:[8,15,16,30,38,46],
        tm:[17,25,33,47],
        tf:[3,4,18,26,34,48]
    }
    var fiveWorld={
        jt:[1,10,24,32,39],
        jd:[2,9,23,31,40],
        mt:[5,13,22,36,44],
        md:[6,14,21,35,43],
        st:[12,20,27,41,49],
        sd:[11,19,28,42],
        ht:[8,15,29,37,46],
        hd:[7,16,30,38,45],
        tt:[3,17,25,34,48],
        td:[4,18,26,33,47]
    }
    var fiveSide={
        jj:[9,10,31,32,39],
        jy:[1,2,23,24,40],
        mj:[5,6,21,22,43,44],
        my:[13,14,35,36],
        sj:[19,20,27,41,42],
        sy:[11,12,28,49],
        hj:[7,8,15,29,30,45,46],
        hy:[16,37,38],
        tj:[3,17,18,33,34],
        ty:[4,25,26,47,48]
    }
    var season={
        c:animal[4].concat(animal[3],animal[5]),
        x:animal[7].concat(animal[6],animal[8]),
        q:animal[10].concat(animal[9],animal[11]),
        d:animal[1].concat(animal[12],animal[2]),
    }
    var seasondx={
        cd:[25,37,49,26,38,36,48],
        cx:[1,13,2,14,12,24],
        xd:[34,46,35,47,33,45],
        xx:[10,22,11,23,9,21],
        qd:[31,43,32,44,30,42],
        qx:[7,19,8,20,6,18],
        dd:[28,40,29,41,27,39],
        dx:[4,16,5,17,3,15]
    }
    var seasonds={
        cd:[1,13,25,37,49],
        cs:[2,14,26,38,12,24,36,48],
        xd:[11,23,35,47,9,21,33,45],
        xs:[10,22,34,46],
        qd:[7,19,31,43],
        qs:[8,20,32,44,6,18,30,42],
        dd:[5,17,29,41,3,15,27,39],
        ds:[4,16,28,40]
    }
    var door={
        1:[1,2,3,4,5,6,7,8,9],
        2:[10,11,12,13,14,15,16,17,18,19],
        3:[20,21,22,23,24,25,26,27,28,29],
        4:[30,31,32,33,34,35,36,37,38,39],
        5:[40,41,42,43,44,45,46,47,48,49]
    }
    var doords={
        d1:[1,3,5,7,9],
        d2:[11,13,15,17,19],
        d3:[21,23,25,27,29],
        d4:[31,33,35,37,39],
        d5:[41,43,45,47,49],
        s1:[2,4,6,8],
        s2:[10,12,14,16,18],
        s3:[20,22,24,26,28],
        s4:[30,32,34,36,38],
        s5:[40,42,44,46,48],
    }
    var art={
        qing:animal[10].concat(animal[4],animal[6]),
        qi:animal[1].concat(animal[2],animal[11]),
        shu:animal[7].concat(animal[5],animal[3]),
        hua:animal[8].concat(animal[9],animal[12])
    }
    var artdx={
        qingd:[31,43,25,37,49,35,47],
        qingx:[7,19,1,13,11,23],
        qid:[28,40,27,39,30,42],
        qix:[4,16,3,15,6,18],
        shud:[34,46,36,48,26,38],
        shux:[10,22,12,24,2,14],
        huad:[33,45,32,44,29,41],
        huax:[9,21,8,20,5,17]
    }
    var animalColor={
        red:animal[1].concat(animal[4],animal[7],animal[10]),
        blue:animal[2].concat(animal[5],animal[8],animal[11]),
        green:animal[3].concat(animal[6],animal[9],animal[12])
    }
    var lastNum={
        0:[10,20,30,40],
        1:[1,11,21,31,41],
        2:[2,12,22,32,42],
        3:[3,13,23,33,43],
        4:[4,14,24,34,44],
        5:[5,15,25,35,45],
        6:[6,16,26,36,46],
        7:[7,17,27,37,47],
        8:[8,18,28,38,48],
        9:[9,19,29,39,49],
    }
    var dxds={
        bigd:[25,27,29,31,33,35,37,39,41,43,45,47,49],
        bigs:[26,28,30,32,34,36,38,40,42,44,46,48],
        smalld:[1,3,5,7,9,11,13,15,17,19,21,23],
        smalls:[2,4,6,8,10,12,14,16,18,20,22,24]
    }
    var fiveAnimal={
        j:animal[9].concat(animal[10]),
        m:animal[3].concat(animal[4]),
        s:animal[1].concat(animal[12]),
        h:animal[6].concat(animal[7]),
        t:animal[2].concat(animal[5],animal[8],animal[11]),
    }
    
    //----推断一----
    //根据往期数字出现次数计算数字占比
    let percen={};
    for (let i = 1; i <= 49; i++) {
        percen[i]=result.filter(v=>v==i).length/result.length*100;
    }
    console.log(percen);
    //根据往期数字计算平均波动
    let sum=0;//总波动值
    for (let key in percen) {
        sum+=Math.abs(percen[key]-1/49*100);
    }
    //给相对于平均概率值，当前概率波动幅度在平均波动值以内的数加分
    for (let key in percen) {
        if(((percen[key]>1/49*100)&&(percen[key]<(1/49*100+sum/49)))||((percen[key]<1/49*100)&&percen[key]<(1/49*100-sum/49))){//波动幅度小于平均波动值
            score[key]++;
        }
    }
    console.log(score);
    // fn(animal,2);//2按肖概率分析
    fn(color,3);//3按颜色概率分析
    fn(five,4);//4按5行概率分析
    fn(season,5);//5按季节分析
    fn(door,6);//6按门数分析
    fn(art,7);//7按艺术分析
    fn(animalColor,8);//8按肖色分析
    fn(lastNum,9); //9按尾数分析
    fn(colorDs,10);//10按颜色单双分析
    fn(colorDx,11);//11按颜色大小分析
    fn(dxds,12);//12按大小单双分析
    fn(fiveAnimal,13);//13按肖5行分析
    fn(fiveDs,14);//14按5行单双分析
    fn(fiveDx,15);//15按5行大小分析
    fn(colorFive,16);//16按5行颜色
    fn(colorDoor,17);//17按门数颜色
    fn(doords,18);//18按门数单双
    // fn(animaldx,19);//19按肖大小
    fn(seasondx,20);//20按季节大小
    fn(seasonds,21);//21按季节单双
    fn(colorSide,22);//22颜色家野
    fn(colorWorld,23);//23颜色天地
    fn(colorSex,24);//24颜色性别
    fn(colorEight,25);//25颜色阴阳
    fn(colorSafe,26);//26颜色凶吉
    // fn(colorSeason,27);//27颜色季节
    // fn(colorArt,28);//28颜色艺术
    fn(artdx,29);//29艺术大小
    fn(colorAnimalColor,30);//30颜色肖色
    // fn(fiveSafe,31);//315行凶吉
    fn(fiveEight,32);//325行阴阳
    fn(fiveSex,33);//335行性别
    fn(fiveWorld,34);//345行天地
    fn(fiveSide,35);//355行家野

    // for (let key in animalPercen) {
    //     if(arr3.length==0){
    //         arr3.push(key);
    //     }else{
    //         for (let i = 0,arr=[...arr3]; i < arr.length; i++) {
    //             if(animalPercen[key]<animalPercen[arr[i]]){
    //                 arr3.splice(i,0,key);
    //                 break;
    //             }
    //             if(i==arr.length-1){
    //                 arr3.push(key);
    //             }
    //         }
    //     }
    // }
    // console.log(arr3)
}
function fn(data,index){
    let percen={};
    for (let key in data) {
        percen[key]=result.filter(v=>data[key].indexOf(v)!=-1).length/result.length*100;
    }
    console.log(percen);
    let sum=0;//总波动值
    for (let key in percen) {
        sum+= Math.abs(percen[key]-data[key].length/49*100);
    }
    //给相对于平均概率值，当前概率波动幅度在平均波动值以内的数加分
    for (let key in percen){
        let p=data[key].length/49*100;//平均值
        console.log(sum/49*data[key].length,sum/Object.keys(percen).length)
        if(
            // ((percen[key]>p)&&(percen[key]<(p+sum/49*data[key].length)))||
            // ((percen[key]<p)&&(percen[key]<(p-sum/49*data[key].length)))
            ((percen[key]>p)&&(percen[key]<(p+sum/Object.keys(percen).length)))||
            ((percen[key]<p)&&(percen[key]<(p-sum/Object.keys(percen).length)))
        ){//波动幅度小于平均波动值
            console.log(index+"："+key);
            for (let item of data[key]){
                score[item]++;
            }
        }
    }
    console.log(score);
}
export{
    guid,getDate,copy,getSystem,getDeviceType,drawCode,formatDate,fullScreen,getIP,test
}