<!-- 底部导航栏 -->
<template>
    <div class="row align-items-center bg-dark bg-opacity-75 text-light d-sm-none">
        <div class="col text-center py-1 d-flex flex-column" :class="{'text-info':isCheckMenu('chat'),'index-1053':$root.teachIndex==0&&$root.isShowTeach}" @click="$root.goPage('help',$root.callInfo,{id:userInfo.guid,name:userInfo.id,type:1,guid:guid})" role="button">
            <i class="bi bi-chat-dots-fill fs-4"></i>
            <span>{{$t("nav.chat")}}</span>
        </div>
        <div class="col text-center py-1 d-flex flex-column" :class="{'text-info':isCheckMenu('home')}" @click="$root.goPage('home')" role="button">
            <i class="bi bi-house-fill fs-4"></i>
            <span>{{$t("nav.home")}}</span>
        </div>
        <div class="col text-center py-1 d-flex flex-column" :class="{'text-info':isCheckMenu('personal'),'index-1053':$root.teachIndex==1&&$root.isShowTeach}" @click="$root.goPage('personal')" role="button">
            <i class="bi bi-gear-fill fs-4"></i>
            <span>{{$t("nav.personal")}}</span>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
    methods: {
        /**
         * 是否选中当前菜单
         */
        isCheckMenu(menuName){
            if(this.$route.name==menuName){
                return true;
            }
            return false;
        },
    },
};
</script>