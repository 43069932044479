<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <div v-if="chatType==1" class="flex-grow-1 card bg-cyan mb-1 mb-sm-0">
            <!-- 联系客服 -->
            <chat v-if="isLoadChat" class="position-absolute start-0 end-0 top-0 index-2 fs-5" :token="token" @close-chat="$router.push({name:'home',params:{guid:userInfo.guid}})" @msg-websocket="msgWebsocket" @send-websocket="sendWebsocket"></chat>
            <div v-else class="d-flex flex-grow-1 justify-content-center align-items-center text-light fs-4 fw-bold">{{ serverTip }}</div>
        </div>
        <div v-else id="chatwootSDKContainer" class="flex-grow-1 card mb-1 mb-sm-0">
            
        </div>
    </div>
</template>
<script>
import { INTERFACE } from "@/assets/js/const.js";
import {IS_DEBUG,COMMAND, USER} from "@/assets/js/config.js"
import Chat from '@/components/public/Chat.vue';
import navbar from '@/components/public/navbar.vue';
import { guid } from "@/assets/js/common.js"
import { nextTick } from '@vue/runtime-core';
export default {
    components:{
        Chat,navbar
    },
    props:{
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
	data() {
		return {
			isLoadChat:false,//是否已点击过客服组件
            chatGuid:"",
            token:"",
            serverTip:"",
            chatType:1,//客服功能类型(1:内置客服功能 2:chatwootSDK)
		};
	},
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
	mounted() {
        this.setWsMessage();
		//if(!this.$root.chatWs){//ws没有连接
            //获取客服连接地址
            this.$emit("ws-send",COMMAND.all_getchat,this.userInfo.id);
        // }else{
        //     this.isLoadChat=true;
            
        // }
        this.$root.hasChatMsg=false;
	},
	methods:{
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$emit("ws-msg",(data)=>{
                switch (data.command){
                    case COMMAND.all_getchat:
                        let index=(window[USER].chatNum&&data.url&&data.url.length>=window[USER].chatNum)?window[USER].chatNum-1:0;//config中有配置使用第几个客服配置并且后台配置有对应的个数则使用配置，否则默认第一个
                        let url=(data.url&&data.url.length>0)?data.url[index]:config().ws;//后台有配置则使用第index个，否则使用config配置项
                        if(url.match(/ws[s]?:\/\//)){//包含ws://或者wss://
                            this.chatType=1;
                            IS_DEBUG&&(url="ws://192.168.1.59:9022?a123");
                            this.serverTip=this.$t("chat.serverCon");
                            if(url&&url!=""&&(url.indexOf("ws")!=-1||url.indexOf("wss")!=-1)){//获取到socket地址并且地址包含ws或者wss
                                this.token=url.substring(url.indexOf('?')+1);
                                this.$root.chatWs=new WebSocket(url);
                                this.$root.chatWs.onopen=()=>{
                                    this.chatGuid=guid();
                                    this.sendWebsocket({
                                        fun:INTERFACE.login,
                                        id:this.userInfo.id,
                                        pwd:"",
                                        name:this.userInfo.id,
                                        type:1,
                                        guid:this.chatGuid,
                                        isvip:1
                                    })
                                    this.serverTip="";
                                    IS_DEBUG&&console.log("客服服务器连接成功");
                                }
                                this.$root.chatWs.onerror=(e)=>{//socket连接错误
                                    this.serverTip=this.$t("chat.serverErr");
                                }
                                this.msgWebsocket();
                                // this.$root.chatWs.onmessage=this.wsMsg;
                            }else{//否则
                                this.serverTip=this.$t("chat.serverUrl");
                            }
                        }else if(url.match(/http[s]?:\/\//)){//包含http://或https://
                            this.chatType=2;
                            window.open(url);

                            // window.chatwootSettings = {showPopoutButton:true,launcherTitle:"联系我们"};
                            // (function (d, t) {
                            //     var BASE_URL = "http://192.168.1.20:8085";
                            //     var g = d.createElement(t),
                            //         s = d.getElementsByTagName(t)[0];
                            //     g.src = BASE_URL + "/sdk.js";
                            //     g.defer = true;
                            //     g.async = true;
                            //     s.parentNode.insertBefore(g, s);
                            //     g.onload = function () {
                            //         window.chatwootSDK.run({
                            //             websiteToken: "111111",
                            //             baseUrl: BASE_URL,
                            //         });
                            //     };
                            // })(document, "script");
                            // (function(d,t) {
                            //     var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
                            //     g.src="http://192.168.1.20:8085/sdk.js";
                            //     g.defer = true;
                            //     g.async = true;
                            //     s.parentNode.insertBefore(g,s);
                            //     window.chatwootSettings={
                            //         type:1,
                            //     };
                            //     g.onload=function(){
                            //         console.dir(window.chatwootSDK.run)
                            //         window.chatwootSDK.run({
                            //             websiteToken: '111111',
                            //             baseUrl: "http://192.168.1.20:8085"
                            //         })
                            //     }
                            // })(document,"script");
                        }
                        break;
                }
            });
        },
        msgWebsocket(onmessage){
            if(this.$root.chatWs){
                let invalid="";//无效字符串
                this.$root.chatWs.onmessage=async (e)=>{
                    IS_DEBUG&&console.log("接收："+e.data);
                    let obj = this.$root.setSocketData(e.data,invalid);
                    let arr=obj.arr;
                    invalid=obj.invalid;
    
                    //接口响应
                    for (let i = 0; i < arr.length; i++){
                        let data = arr[i];
                        IS_DEBUG&&console.log("接收【转换】："+JSON.stringify(data));

                        switch (data["fun"]) {
                            case INTERFACE.login:
                                this.isShowLoad=false;
                                let loginResult=data["result"];
                                if(loginResult==1){
                                    history.pushState({
                                        "id":this.userInfo.id,
                                        "name":this.userInfo.id,
                                        "guid":this.chatGuid,
                                        "type":1,
                                        "power":data["power"],
                                        "imgurl":data["iurl"],
                                    },"");
                                    this.isLoadChat=true;

                                    // this.$router.push({
                                    //     name:'home',
                                    //     state:{
                                    //         id:this.userInfo.guid,
                                    //         name:data["name"],
                                    //         type:1,
                                    //         guid:guid(),
                                    //         power:data["power"],
                                    //         imgurl:data["iurl"]
                                    //     }
                                    // },);
                                }else{
                                    useLayer({str:"客服系统登录失败"});
                                }
                                this.isLoginEnable=true;
                                break;
                        }
                        
                        onmessage&&onmessage(data);
                        await nextTick();//加上这个以免因连包导致循环赋值，但因为vue的机制使循环最后一次才能监听到
                    }
    
                }
            }
        },
        sendWebsocket(obj){
            if(this.$root.chatWs){
                console.log("发送："+JSON.stringify(obj));
                this.$root.chatWs.send(JSON.stringify(obj));
            }
        },

	},
}
</script>